.flpsDashboarContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1100px;
	margin-top: 2rem;
	padding: 2rem;
}

/* Mobile devices */
@media (max-width: 910px) {
	.flpsDashboarContainer {
		width: 65%;
	}
}
/* Mobile Mini devices */
@media (max-width: 700px) {
	.flpsDashboarContainer {
		width: 100%;
	}
}
