.logoBtn {
	transition: ease-in 0.15s;
}
.logoBtn:hover {
	cursor: pointer;
	transform: scale(0.95);
}
.navbarFPLogo {
	margin-left: 2rem;
	width: 18rem;
	height: auto;
}
.inputNavBarBtn {
	background-color: #ef3024;
	color: #fefefe;
	min-width: 2.5rem;
	min-height: 2.5rem;
	border-radius: 50%;
	font-size: 1.5rem;
	border: none;
}
.inputMetodoPagoBtn {
	background-color: transparent;
	color: #ef3024;
	min-width: 2.5rem;
	min-height: 2.5rem;
	border-radius: 50%;
	font-size: 2rem;
	border: none;
}
.inputNavBarBtnOpen {
	margin-right: 0.9rem;
}
.inputNavBarBtn:hover {
	cursor: pointer;
	background-color: #d22d21;
	border: 0.5px solid #fefefe;
}
.inputMetodoPagoBtn:hover {
	cursor: pointer;
	color: #d62c20;
}
.navbarSearch {
	display: flex;
}
.navbarSearch2 {
	display: none;
}

/* Tablets */
@media (max-width: 1199px) {
	.navbarFPLogo {
		margin-left: 2rem;
		width: 12rem;
	}
}
/* Mobile devices */
@media (max-width: 673px) {
	.inputNavBarBtnOpen {
		margin-right: 0rem;
	}
	.navbarSearch {
		display: none;
	}
	.navbarSearch2 {
		display: flex;
	}
	.inputNavBarBtn {
		min-width: 2rem;
		min-height: 2rem;
	}
	.navbarFPLogo {
		margin-left: 2rem;
		width: 10rem;
	}
}
