.miPerfilContent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	background-color: transparent;
	overflow: hidden;
	padding: 2rem;
}
.miPerfilContentCard {
	box-shadow: 1px 1px 10px 3px #d5d5d5;
	border-radius: 12px;
	padding: 2rem;
}

/* Mobile devices */
@media (max-width: 1102px) {
	.miPerfilContent {
		width: 100%;
	}
}
/* Mobile Mini devices */
@media (max-width: 700px) {
	.miPerfilContent {
		width: 100%;
	}
}
