/* .flpssCheckbox:checked {
	border: none;
	outline: 2px solid deeppink;
} */

.flpssCheckbox {
	-moz-appearance: none;
	-webkit-appearance: none;
	-o-appearance: none;
	outline: none;
	/* content: none; */
}

.flpssCheckbox:before {
	/* font-family: 'FontAwesome'; */
	content: '✓';
	font-size: 20px;
	line-height: 20px;
	color: transparent !important;
	background: transparent;
	display: block;
	width: 20px;
	height: 20px;
	border: 2px solid rgb(234, 51, 51);
	margin-right: 7px;
}

.flpssCheckbox:checked:before {
	color: black !important;
}
