.inputCategoria {
	border-radius: 8px;
	border: 1px solid #b6b6b6;
	padding: 0.7rem;
	background-color: #f9f9f9;
	color: #b6b6b6;
	width: 100%;
}
.inputCategoria:focus {
	/* outline: 1px solid #ef3024; */
	outline: none;
	border: 1px solid #848484;
}
.inputCategoria::placeholder {
	color: #b2b2b2;
	opacity: 1; /* Firefox */
	font-weight: bold;
	/* color: #0a2c92; */
}

.inputCategoria:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #b2b2b2;
	font-weight: bold;
}

.inputCategoria::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #b2b2b2;
	font-weight: bold;
}

.fpInputFile {
	width: 150px;
	height: 150px;
	max-width: 100%;
	color: #b2b2b2;
	padding: 5px;
	background: transparent;
	border-radius: 10px;
	border: 1px solid #b2b2b2;
	cursor: pointer;
	z-index: 500;
}

.fpInputFile::file-selector-button {
	/* display: none; */
	margin-left: -78%;
	margin-right: 20%;
	margin-top: 50%;
	border: none;
	background: transparent;

	color: #fff;
	cursor: pointer;
	/* transition: background 0.2s ease-in-out; */
}
.fpInputFileIMGContainer {
	z-index: 400;
	position: absolute;
	width: 150px;
	height: 150px;
	object-fit: cover;
	object-position: center;
	overflow: hidden;
	border-radius: 10px;
}
.fpInputFileIMG {
	position: absolute;
	/* width: 100%; */
	height: 100%;
}

.fpInputFileImg {
	width: 150px;
	height: 150px;
	max-width: 100%;
	/* color: #b2b2b2; */
	color: transparent;
	padding: 5px;
	background: transparent;
	border-radius: 10px;
	border: 1px solid #b2b2b2;
	cursor: pointer;
	z-index: 500;
}

.fpInputFileImg::file-selector-button {
	/* display: none; */
	margin-left: -78%;
	margin-right: 20%;
	margin-top: 50%;
	border: none;
	background: transparent;

	color: #fff;
	cursor: pointer;
	/* transition: background 0.2s ease-in-out; */
}
