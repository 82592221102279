.MyEventListItem {
	width: auto;
	max-width: 550px;
	/* width: 550px; */
	padding: 3rem 4rem;
	margin-left: -2rem;
	transition: ease-in 0.1s;
}
.btnArrow {
	position: absolute;
	border: 1px solid #767676;
	color: #767676;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	padding: 0.1rem;

	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 500;
}
.btnArrow:hover {
	cursor: pointer;
	background-color: #000000;
	color: #ffffff;
}
.btnArrowBack {
	top: 0px;
	right: 3rem;
}
.btnArrowNext {
	top: 0px;
	right: 0rem;
}
.myTicketCard {
	border-radius: 14px;
	overflow: hidden;
	background-color: #f9f9f9;
	margin-top: 2rem;
}

/* Responsive */
@media (max-width: 1010px) {
}
/* Mobile devices */
@media (max-width: 899px) {
	.MyEventListItem {
		width: 400px;
		padding: 4rem 2rem;
	}
}
/* Mobile Mini devices */
@media (max-width: 519px) {
	.MyEventListItem {
		width: 95%;
		padding: 4rem 0.1rem;
		margin-left: 0rem;
	}
}
