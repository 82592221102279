.fpSidebarContainer {
	display: flex;
}
/* Mobile devices */
@media (max-width: 899px) {
}
/* Mobile Mini devices */
@media (max-width: 700px) {
	.fpSidebarContainer {
		display: none;
	}
}
