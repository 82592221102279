.fpSidebarContent {
	width: 250px;
	/* background-color: aquamarine; */
	display: flex;
	flex-direction: column;
	padding: 2rem;
}
.fpSidebarTitle {
	background-color: var(--colorPrimary);
	display: flex;
	color: #ffffff;
	padding: 0.5rem;
	align-items: center;
	border-radius: 5px;
	margin-bottom: 1rem;
}
.fpSidebarItem {
	color: var(--colorSecondary);
	padding: 0.5rem;
	display: flex;
}
.fpSidebarItemActive {
	color: var(--colorPrimary);
	padding: 0.5rem;
	display: flex;
}
.fpSidebarItemText {
	padding: 0px 1rem;
}
