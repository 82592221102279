.eventoXCiudadItem {
	width: 350px;
	padding: 1rem;
}
.categoryCard {
	width: 100%;
	height: 100%;
	position: relative;
	border-radius: 10px;
	display: flex;
	overflow: hidden;
	transition: ease-in 0.15s;
	box-shadow: 1px 1px 10px 3px #52525272;
}
.categoryCard:hover {
	cursor: pointer;
	transform: scale(0.9);
}
.eventoXCiudadTextBar {
	position: absolute;
	top: 40%;
	/* transform: translate(0%, -50%); */
	width: 100%;
	background-color: transparent;
	color: #ffffff;
	-webkit-text-stroke: 0.5px #b4b4b4; /* width and color */
	font-size: 1.2rem;
	font-weight: bold;

	display: flex;
	justify-content: center;
	padding: 0.5rem;
	font-weight: bold;
}

/* Mobile devices */
@media (max-width: 673px) {
	.eventoXCiudadItem {
		max-height: 210px;
	}
	.eventoXCiudadTextBar {
		font-size: 1.4rem;
	}
}
