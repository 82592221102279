.categoryItem {
	width: 350px;
	padding: 1rem;
}
.categoryCard {
	width: 100%;
	height: 100%;
	position: relative;
	border-radius: 10px;
	display: flex;
	overflow: hidden;
	transition: ease-in 0.15s;
	box-shadow: 1px 1px 10px 3px #52525272;
}
.categoryCard:hover {
	cursor: pointer;
	transform: scale(0.9);
}
.categoryTextBar {
	position: absolute;
	bottom: 0px;
	width: 100%;
	background-color: #000000;
	color: #ffffff;
	display: flex;
	justify-content: center;
	padding: 0.5rem;
	font-weight: bold;
}

/* Mobile devices */
@media (max-width: 673px) {
	.categoryItem {
		width: 170px;
		padding: 0.2rem;
	}
	.categoryTextBar {
		padding: 0.2rem;
		font-size: 1rem;
	}
}
