.fp-calendar {
	border: none;
}
.fp-calendar::-webkit-calendar-picker-indicator {
	background-image: url('assets/images/icons/arrowDownRed.svg');
}
.fp-calendar:active {
	border: none;
}
.fp-calendar:focus {
	border: none;
	outline: none;
}
