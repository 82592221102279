.eventDescImgContainer {
	width: 100%;
	min-height: 170px;
	max-height: 480px;
}
.eventDescInfoSection {
	display: flex;
	flex-direction: column;
	max-width: 600px;
	padding: 1rem;
}
.eventDescTicketSection {
	width: 100%;
	min-width: 550px;
	margin-top: 1rem;
	overflow: hidden;
	background-color: var(--backgroundBody);
	border-radius: 10px;
	box-shadow: 1px 1px 8px 1px #c5c5c5;
}
.eventDescTicketBtn {
	width: 3rem;
	height: 3rem;
	border: none;
	background-color: transparent;
	font-size: 1.5rem;
	transition: ease-in 0.1s;
}
.eventDescTicketBtn:hover {
	cursor: pointer;
	transform: scale(1.2);
}
.eventDescTicketBtn:active {
	color: #086086;
}
.eventConfirmContainerDesc {
	display: flex;
	width: 50%;
	min-width: 300px;
	/* max-width: 600px; */
	/* padding: 1rem; */
}
.eventCard {
	display: flex;
	width: 100%;
	flex-direction: column;
	overflow: hidden;
	background-color: var(--backgroundBody);
	border-radius: 10px;
	box-shadow: 1px 1px 8px 1px #c5c5c5;
	margin: 1rem;
	justify-content: space-between;
	/* padding: 3rem; */
}
.eventCardDesc {
	padding: 3rem;
}

/* Mobile devices */
@media (max-width: 899px) {
}
/* Mobile Mini devices */
@media (max-width: 700px) {
	.eventDescInfoSection {
		margin-top: -1rem;
		background-color: #eff2f5;
		border-radius: 1rem 1rem 0rem 0rem;
	}
	.eventDescTicketSection {
		min-width: 100%;
	}
	.eventConfirmContainerDesc {
		display: flex;
		width: 100%;
		min-width: 300px;
		/* max-width: 600px; */
		padding: 1rem;
	}
}
