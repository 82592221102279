.myEventTextBotom1 {
	font-size: 1rem;
	font-weight: bold;
	color: var(--backgroundBody);
	max-width: 600px;
}
.myEventTextBotom2 {
	font-size: 13px;
	font-weight: 300;
	color: var(--backgroundBody);
	max-width: 650px;
}

/* Mobile devices */
@media (max-width: 899px) {
}
/* Mobile Mini devices */
@media (max-width: 700px) {
}
