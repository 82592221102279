.fpTableContainer {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	width: 100%;
	min-width: 30px;
	overflow-x: scroll;
}
.fpTableContainer::-webkit-scrollbar {
	display: none;
}
.fp-select {
	/* margin: 20px auto 40px auto; */
	border-bottom: 1px solid #d9d9d9c7;
	height: 30px;
	overflow: hidden;
	width: 230px;
	position: relative;
}
.fp-selected {
	background: transparent;
	border: none;
	font-size: 14px;
	height: 30px;
	padding: 5px;
	width: 250px;
}

.fp-selected:focus {
	outline: none;
}

.fp-select::after {
	/* content: '\025be'; */
	content: url('assets/images/icons/arrowDownRed.svg');
	transform: scale(0.6);
	display: table-cell;
	padding-top: 0px;
	text-align: center;
	width: 30px;
	height: 30px;
	background-color: transparent;
	position: absolute;
	top: 0;
	right: 0px;
	pointer-events: none;
}
.fp-selectUser {
	/* margin: 20px auto 40px auto; */
	border-bottom: 1px solid #d9d9d9c7;
	height: 30px;
	overflow: hidden;
	width: 100%;
	position: relative;
}
.fp-selectedUser {
	background: transparent;
	border: none;
	font-size: 14px;
	height: 30px;
	padding: 5px;
	width: 110%;
}

.fp-selectedUser:focus {
	outline: none;
}

.fp-selectUser::after {
	/* content: '\025be'; */
	content: url('assets/images/icons/arrowDownRed.svg');
	transform: scale(0.6);
	display: table-cell;
	padding-top: 0px;
	text-align: center;
	width: 30px;
	height: 30px;
	background-color: transparent;
	position: absolute;
	top: 0;
	right: 0px;
	pointer-events: none;
}
.flpsCbasicCardContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}
.fp-selectRadius {
	/* margin: 20px auto 40px auto; */
	border: 1px solid #d9d9d9c7;
	background-color: #eff2f5;
	border-radius: 5px;
	height: 45px;
	overflow: hidden;
	width: 230px;
	position: relative;
}
.fp-selectedRadius:hover {
	cursor: pointer;
}
.fp-selectedRadius {
	background: transparent;
	border: none;
	font-size: 14px;
	height: 30px;
	padding: 5px;
	width: 250px;
}

.fp-selectedRadius:focus {
	outline: none;
}

.fp-selectRadius::after {
	/* content: '\025be'; */

	content: url('assets/images/icons/arrowDownWhite.svg');
	transform: scale(0.6);
	line-height: 6rem;
	display: table-cell;
	padding-top: 0px;
	text-align: center;
	width: 50px;
	height: 200%;
	background-color: red;
	position: absolute;
	top: -20px;
	right: -10px;
	pointer-events: none;
}

/* Mobile devices */
@media (max-width: 1066px) {
}
/* Mobile Mini devices */
@media (max-width: 910px) {
}
