/* --------------------- */
/* Styles to carousel in dashboard */
.carouselContenedor {
	position: absolute;
	display: flex;
	flex-direction: column;
	height: 600px;
	/* height: 100%; */
	/* max-height: 600px; */
	justify-content: flex-end;
	/* background-color: rgb(74, 35, 216); */
}
.carouselImg {
	height: 600px;
	max-height: 600px;
}
.carouselImg img {
	filter: brightness(70%);
}

.carouselTittle {
	color: #ffffff;
	font-size: 3rem;
	font-weight: bold;
}
.carouselSubtittle {
	color: #ffffff;
	font-size: 2.5rem;
	font-weight: bold;
}
.carouselSubtittle2 {
	color: #ffffff;
	font-size: 2rem;
	font-weight: bold;
}
#carouselBtn {
	width: 14rem;
	background-color: #ef3024;
	color: #ffffff;
	font-weight: bold;
	font-size: 1rem;
	margin-top: 1rem;
}

/* Tablets */
@media (max-width: 1199px) {
	/* --------------------- */
	/* Styles to carousel in dashboard */
	.carouselTittle {
		color: #ffffff;
		font-size: 2rem;
		font-weight: bold;
	}
	.carouselSubtittle {
		color: #ffffff;
		font-size: 1.5rem;
		font-weight: bold;
	}
	.carouselSubtittle2 {
		color: #ffffff;
		font-size: 1rem;
		font-weight: bold;
	}
	#carouselBtn {
		width: 11rem;
		background-color: #ef3024;
		color: #ffffff;
		font-weight: bold;
		font-size: 0.8rem;
		margin-top: 1rem;
	}
}
/* Mobile devices */
@media (max-width: 673px) {
	/* --------------------- */
	/* Styles to carousel in dashboard */
	.carouselContenedor {
		height: 350px;
	}
	.carouselImg {
		height: 350px;
	}
	.carouselTittle {
		color: #ffffff;
		font-size: 1rem;
		font-weight: bold;
	}
	.carouselSubtittle {
		color: #ffffff;
		font-size: 0.9rem;
		font-weight: bold;
	}
	.carouselSubtittle2 {
		color: #ffffff;
		font-size: 0.9rem;
		font-weight: bold;
	}
	#carouselBtn {
		width: 9rem;
		background-color: #ef3024;
		color: #ffffff;
		font-weight: bold;
		font-size: 0.56rem;
		margin-top: 0.2rem;
	}
}
