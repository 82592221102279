.cardInfoSectionContainer {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
}
.cardInfoSection {
	display: flex;
	flex-direction: column;
	width: 240px;
	height: 240px;
	margin: 0.5rem;
	background-color: var(--backgroundBody);
	border-radius: 5px;
	box-shadow: 1px 1px 5px 2px #c7c7c7;
	justify-content: space-between;
	overflow: hidden;
	align-items: center;
	text-align: center;
}
.cardInfoTittle {
	font-weight: bold;
	color: var(--colorPrimary);
	font-size: 3rem;
}
.cardInfoSubittle {
	font-weight: bold;
	color: var(--textColorPrimary);
	font-size: 2rem;
}
.cardInfoBody {
	font-weight: 300;
	color: var(--textColorPrimary);
	font-size: 0.5;
}
/* Mobile devices */
@media (max-width: 899px) {
	.cardInfoSectionContainer {
		justify-content: center;
	}
}
/* Mobile Mini devices */
@media (max-width: 510px) {
	.cardInfoSection {
		margin: 0.3rem;
		width: 100%;
		height: 100%;
		min-width: 132px;
		max-width: 133px;
		min-height: 132px;
	}
	.cardInfoTittle {
		font-size: 1.2rem;
	}
	.cardInfoSubittle {
		font-size: 1rem;
	}
	.cardInfoBody {
		font-size: 0.9rem;
	}
}
