.cardMetodoPAgoContainer {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	box-sizing: border-box;
}
.cardMetodoPago60 {
	display: flex;
	padding: 1rem;
	width: 60%;
	margin-top: -2.5rem;
}
.cardMetodoPago40 {
	display: flex;
	padding: 1rem;
	width: 40%;
}
/* Mobile devices */
@media (max-width: 933px) {
	.cardMetodoPAgoContainer {
		max-width: 450px;
	}
	.cardMetodoPago60 {
		width: 100%;
	}
	.cardMetodoPago40 {
		width: 100%;
	}
}
/* Mobile Mini devices */
@media (max-width: 733px) {
	.cardMetodoPago60 {
		width: 100%;
	}
	.cardMetodoPago40 {
		width: 100%;
	}
}

/* 930 - 700 */
