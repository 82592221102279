.carousel-container {
	width: 100%;
	margin-top: -2rem;
	padding-top: 2rem;
	padding-left: 0rem;
	/* z-index: 200; */
}

.EventoDestacadoItem {
	width: 510px;
	/* width: 550px; */
	padding: 3rem 4rem;
	margin-left: -2rem;
	transition: ease-in 0.1s;
}
.EventoDestacadoItem:hover {
	cursor: pointer;
	transform: scale(1.05);
}
.btnArrow {
	position: absolute;
	border: 1px solid #767676;
	color: #767676;
	border-radius: 50%;
	width: 35px;
	height: 35px;
	padding: 0.1rem;

	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 500;
}
.btnArrow:hover {
	cursor: pointer;
	background-color: #000000;
	color: #ffffff;
}
.btnArrowBack {
	top: 0px;
	right: 3rem;
}
.btnArrowNext {
	top: 0px;
	right: 0rem;
}
/* Mobile devices */
@media (max-width: 899px) {
	.carousel-container {
		padding-left: 0rem;
		display: flex;
	}
	.EventoDestacadoItem {
		width: 360px;
		padding: 4rem 2rem;
	}
}
/* Mobile Mini devices */
@media (max-width: 700px) {
	.EventoDestacadoItem {
		width: 370px;
		padding: 4rem 2rem;
		margin-left: 0rem;
	}
}
