.eventoProximoItem {
	width: 350px;
	padding: 1rem;
}
.eventoProximoCard {
	width: 100%;
	height: 100%;
	max-height: 160px;
	position: relative;
	border-radius: 10px;
	display: flex;
	overflow: hidden;
	transition: ease-in 0.15s;
}
