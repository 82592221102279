* {
	--backgroundBody: #ffffff;
	--backgroundPrimary: #ffffff;
	--backgroundBodyMUI: #eff2f5;

	--colorPrimary: #ef3024;
	--colorSecondary: #000000;
	--colorTertiary: #b6b6b6;

	--colorSecondaryHover: #494949;
	--colorOptionHover: #eaeaea;
	--colorSecondaryActive: #086086;
	--colorVerde: #1ccf01;

	--textColorPrimary: #000000;
	--textColorSecondary: #888888;
	--textColorLight: #888888;
	--textColorDisable: #d1d1d1;

	--textColorError1: #ef3024;
	--textColorError2: #f4524f;
	--test: #a2a2a2a7;

	box-sizing: border-box;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	/* font-family: 'SF Mono', monospace; */
	font-family: Arial, Helvetica, sans-serif;
	background-color: var(--backgroundBody);
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.displayFlex {
	display: flex;
}
.alignItemsCenter {
	align-items: center;
}
.justifyContentCenter {
	justify-content: center;
}
.inputTransparent {
	border-radius: 5px 0px 0px 5px;
	border-bottom: 2px solid #b2b2b2;
	border-left: 2px solid #b2b2b2;
	border-top: 2px solid #b2b2b2;
	padding: 0.7rem;
	background-color: transparent;
	color: #b2b2b2;
}
.inputTransparent:focus {
	/* border-radius: 5px 0px 0px 5px; */
	outline: none;
	border-bottom: 2px solid #b6b6b6;
	border-left: 2px solid #b6b6b6;
	border-top: 2px solid #b6b6b6;
}
.inputTransparent::placeholder {
	color: #b2b2b2;
	opacity: 1; /* Firefox */
	font-weight: bold;
	/* color: #0a2c92; */
}

.inputTransparent:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #b2b2b2;
	font-weight: bold;
}

.inputTransparent::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #b2b2b2;
	font-weight: bold;
}
.inputTransparent2 {
	min-width: 280px;
	border-radius: 5px 0px 0px 5px;
	border-bottom: 2px solid #b6b6b6;
	border-left: 2px solid #b6b6b6;
	border-top: 2px solid #b6b6b6;
	padding: 0.7rem;
	background-color: transparent;
	color: #626262;
}
.inputTransparent2:focus {
	/* border-radius: 5px 0px 0px 5px; */
	outline: none;
	border-bottom: 2px solid #626262;
	border-left: 2px solid #626262;
	border-top: 2px solid #626262;
}
.inputTransparent2::placeholder {
	color: #b6b6b6;
	opacity: 1; /* Firefox */
	font-weight: bold;
	/* color: #0a2c92; */
}

.inputTransparent2:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #b6b6b6;
	font-weight: bold;
}

.inputTransparent2::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #b6b6b6;
	font-weight: bold;
}
#inputSearchBtn {
	background-color: #ef3024;
	color: #fefefe;
	min-width: 3rem;
	border-radius: 0px 5px 5px 0px;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
}
#inputSearchBtn:hover {
	cursor: pointer;
	background-color: #d22d21;
	border: 0.5px solid #fefefe;
}
.textBtn:hover {
	cursor: pointer;
}

/* Mobile devices */
@media (max-width: 899px) {
}
/* Mobile Mini devices */
@media (max-width: 700px) {
}
