.filterValueButton {
	border: 1px solid var(--colorSecondary);
	border-radius: 20px;
	padding: 5px 10px;
	margin: 15px 5px;
	font-size: 1rem;
	display: flex;
	align-items: center;
	user-select: none;
}
.filterValueDeleteButton {
	height: 2rem;
	width: 2rem;
	border: none;
	background-color: none;
	font-size: 1.2rem;
	padding: 5px;
	/* background-color: aqua; */
	display: flex;
	justify-content: center;
	align-items: center;
	transition: ease-in 0.15s;
}
.filterValueDeleteButton:hover {
	cursor: pointer;
	font-size: 1.6rem;
}
